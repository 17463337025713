<template>
    <div>
        <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
            {{ successMessage }}
        </div>
        <b-card>
            <b-row>
                <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                Personal Information
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="First Name:" label-for="first_name">
                                    <b-form-input id="first_name" type="text" placeholder="Enter first name" autocomplete="off"
                                        v-model="first_name" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Middle Name:" label-for="second_name">
                                    <b-form-input id="second_name" type="text" placeholder="Enter  second name" autocomplete="off"
                                        v-model="second_name" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Last Name:" label-for="last_name">
                                    <b-form-input id="last_name" type="text" placeholder="Enter  last name" autocomplete="off"
                                        v-model="last_name" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Second Surname:" label-for="second_last_name">
                                    <b-form-input id="second_last_name" type="text" placeholder="Enter second surname" autocomplete="off"
                                        v-model="second_last_name" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Date of Birth:" label-for="birthdate">
                                    <b-form-input id="birthdate" type="date" placeholder="Enter date of  birth" autocomplete="off"
                                        v-model="birthdate" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Profession:" label-for="profession">
                                    <b-form-input id="profession" type="text" placeholder="Enter profession" autocomplete="off"
                                        v-model="profession" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Voter Credendial Number:" label-for="voter_credential_number">
                                    <b-form-input id="voter_credential_number" type="text" placeholder="Enter voter credential number" autocomplete="off"
                                        v-model="voter_credential_number" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Zip Code:" label-for="postcode">
                                    <b-form-input id="postcode" type="text" placeholder="Enter Zip code"
                                        v-model="postcode" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Address:" label-for="street_address">
                                    <b-form-input id="street_address" type="text" placeholder="Enter street address"
                                        v-model="street_address" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <!-- <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Type:" label-for="type">
                                    <b-form-input id="type" type="text" placeholder="Enter type"
                                        v-model="type" required>
                                    </b-form-input>
                                </b-form-group>
                            </div> -->
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Email Address:" label-for="email">
                                    <b-form-input id="email" type="email" placeholder="Enter email address"
                                        v-model="email" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Mobile:" label-for="phone_number">
                                    <b-form-input id="phone_number" type="number" placeholder="Enter mobile number"
                                        v-model="phone_number" required></b-form-input>
                                </b-form-group>
                            </div>
                           
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="City:" label-for="city">
                                    <b-form-input id="city" placeholder="Enter city" v-model="city" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Country:" label-for="country">
                                    <b-form-input id="country" placeholder="Enter country" v-model="country" required></b-form-input>
                                </b-form-group>
                            </div>
            
                            <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Status:" label-for="status">
                                <b-form-select
                                id="status"
                                v-model="status"
                                :options="statusOptions"
                                required
                                ></b-form-select>
                            </b-form-group>
                            </div>


                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Profile Picture:" label-for="image">
                                    <div style="margin-left: 3px; margin-bottom: 15px">
                                        <img v-if="editedUser.image" :src="'https://wossdb.wossautoid.com/' + image
                                            " alt="Picture" style="max-width: 100px; max-height: 100px" />
                                        <input type="file" accept="image/*" id="image"
                                            @change="onProfilePictureChange" />
                                    </div>
                                </b-form-group>
                            </div>
                            <!-- Document  code  here  -->
                        
                    <div class="col-md-4 col-12">
                <b-form-group id="input-group-2" label="Document:" label-for="documents">
                    <div style="margin-left: 3px; margin-bottom: 15px">
                    <!-- Document Preview -->
                    <div v-if="editedUser.documents">
                        <a :href="'https://wossdb.wossautoid.com/' + editedUser.documents" target="_blank">
                        <i class="fas fa-file-pdf" style="margin-right: 5px;"></i> PDF
                        </a>
                    </div>
                    <input type="file" accept=".pdf,.doc,.docx" id="documents" @change="onProfilePictureChangeDocument" />
                    </div>
                </b-form-group>
                </div>


                        </div>

                        <!-- Bank Information End -->
                        <b-button type="submit" variant="primary" class="mb-8 mr-8" :disabled="isLoading">
                            <span v-if="!isLoading">Submit</span>
                            <b-spinner v-else class="mb-8 mr-8" variant="primary" small></b-spinner>
                        </b-button>
                    </b-form>
                </div>
                <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
                    <pre v-highlightjs>
            <code class="hljs html">
              {{ codeText }}
            </code>
          </pre>
                </div>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BSpinner,
} from "bootstrap-vue";
// import axios from "axios";
import axios from "../../../axios";
import { BToast } from "bootstrap-vue";
import code from "@/view/components/data-entry/form/code";

export default {
    data() {
        return {
            show: true,
            codeText: code.introduction,
            codeActive: false,
            codeActiveClass: false,
            selectedOption: "",
            inputField1: "",
            inputField2: "",
            inputField3: "",
            showModal: false,
            isLoading: false,

            first_name: "",
            second_name:"",
            last_name:"",
            second_last_name:"",
            birthdate:"",
            profession:"",
            voter_credential_number:"",
            postcode:"",
            street_address:"",
            type:"user",
            // documents:"",
             documents: null, 
            email: "",
            status: "",
           
      statusOptions: [
        { value: null, text: 'Select status of vehicle' },
        { value: 'Pending', text: 'Pending' },
        { value: 'Approved', text: 'Approved' },
        { value: 'Rejected', text: 'Rejected' }
      ],
            phone_number: "",
            city:"",
            country:"",
            image: "",
            editedUser: {},


        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BToast, // Add this line
        BSpinner,
    },

    created() {
        // Load the clients data when the component is created
        axios
        const userId = this.$route.params.id;
        axios
            .get(`UserGet/${userId}`)
            .then((response) => {

                this.editedUser = response.data.data;
                this.first_name = this.editedUser.first_name;
                this.second_name = this.editedUser.second_name;
                this.last_name = this.editedUser.last_name;
                this.second_last_name = this.editedUser.second_last_name;
                this.birthdate = this.editedUser.birthdate;
                this.profession = this.editedUser.profession;
                this.voter_credential_number = this.editedUser.voter_credential_number;
                this.postcode = this.editedUser.postcode;
                this.type = this.editedUser.type;
                this.street_address = this.editedUser.street_address;
                this.phone_number = this.editedUser.phone_number;
                this.city = this.editedUser.city;
                this.email = this.editedUser.email;
                this.country = this.editedUser.country;
                this.status = this.editedUser.status;
                this.image = this.editedUser.image;
                this.documents = this.editedUser.documents;
            
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });
    },
    methods: {
        showMsgBoxOne() {
            debugger;
        },
        onSubmit(event) {
            event.preventDefault();
            alert(JSON.stringify(this.form));
            // debugger
        },
      
        addUser() {
            this.isLoading = true;
            // Create a FormData object to handle the image file

            const formData = new FormData();
            formData.append("first_name", this.first_name);
            formData.append("second_name", this.second_name);
            formData.append("last_name", this.last_name);
            formData.append("second_last_name", this.second_last_name);
            formData.append("birthdate", this.birthdate);
            formData.append("profession", this.profession);
            formData.append("voter_credential_number", this.voter_credential_number);
            formData.append("postcode", this.postcode);
            formData.append("type", this.type);
            formData.append("street_address", this.street_address);
            formData.append("email", this.email);
            formData.append("phone_number", this.phone_number);
            formData.append("city", this.city);
            formData.append("country", this.country);
            formData.append("status", this.status);
            formData.append("image", this.image);
            // formData.append("documents", this.documents);
   
    if (this.documents) {
        formData.append("documents", this.documents); // Ensure documents are uploaded correctly
      }
         
            axios
                .post(`updateReguest/${this.editedUser.id}`, formData)
                .then((response) => {
                    console.log(response.data);
                    this.$bvToast.toast("Request Update successfully!", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                        appendToast: true,
                        toaster: "b-toaster-top-right",
                        autoHideDelay: 5000,
                        variant: "primary", // Background color
                    });
                    this.isLoading = false;
                    this.$router.push({ name: 'ListRequest' });
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    console.log(error.response.data);
                    this.isLoading = false;
                });
        },

        onProfilePictureChange(event) {
            const file = event.target.files[0];
            if (file) {
                // Set the selected file to the data property
                this.image = file;
            }
        },
      
        onProfilePictureChangeDocument(event) {
            const file = event.target.files[0];
            if (file) {
                this.documents = file;
            }
            },

        codeClick() {
            this.codeActive = !this.codeActive;
        },

        saveOwnCar() {
            this.showModal = false;
        },
    },
};
</script>